<template>
    <div>
        <Multiselect
                :class="{sm: isSmall}"
                :value="value"
                :options="entities"
                :searchable="searchable"
                :show-labels="showLabels"
                :multiple="multiple"
                :placeholder="computedPlaceholder"
                :label="label"
                :track-by="trackBy"
                :close-on-select = "closeOnSelect"
                :clear-on-select = "clearOnSelect"
                :show-no-results = "showNoResults"
                @input="onInput"
        >
            <template slot="selection" slot-scope="props">
                <div class="multiselect__tags-wrap">
                    <span
                            class="multiselect__tag"
                            v-for="item in props.values"
                            :key="item[trackBy]"
                    >
                        <span>
                            {{item[label]}}
                        </span>
                        <span style="height: 16px">
                            <button type="button" @click="() => props.remove(item[trackBy])">
                                <ico name="icoCancel" size="16" color="#79919C"/>
                            </button>
                        </span>
                    </span>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import {isEmpty} from 'lodash'

    export default {
        name: "EntitySelect",
        components: {
            Multiselect,
        },
        props: {
            isSmall: {
                type: Boolean,
            },
            value: {
                required: true,
            },
            entities: {
                type: Array,
                required: true,
            },
            label: {
                type: String,
                required: false,
                default: 'value',
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false,
            },
            clearOnSelect:{
              type: Boolean,
              default: false,
            },
            closeOnSelect:{
              type:Boolean,
              default: false,
            },
            showNoResults:{
              default: false,
            },
            placeholder: {
                type: String,
                required: false,
                default: null,
            },
            searchable: {
                type: Boolean,
                required: false,
                default: true,
            },
            showLabels: {
                type: Boolean,
                required: false,
                default: false,
            },
            trackBy: {
                type: String,
                required: false,
                default: 'id',
            },
            isUpdatable: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        computed: {
            computedPlaceholder() {
                return this.value && this.value.length > 0 ? '' : this.placeholder;
            }
        },
        methods: {
            onInput(value) {
                this.$emit('onChange', value)
            },
        },
        created() {
            if (this.isUpdatable || isEmpty(this.entities)) {
                this.$emit('onLoad')
            }
        }
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';
    @import '../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

    .multiselect {
        min-height: 40px;
    }
    .multiselect__select {
        min-height: calc(100% - 2px);
        &::before {
            border-color: @bg-color transparent transparent;
            color: @bg-color;
        }

    }
    .multiselect__tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: @br;
        border: 2px solid @text-color-lighter;
        margin: 1px 1px 1px 0;
        padding: 0 40px 0 16px;
        height: 100%;
        min-height: 44px;

        @media(max-height: 720px) {
            min-height: 40px;
        }
    }
    .multiselect__tags-wrap {
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 1px 1px 1px 0;
        padding: 8px 0 8px 0;
        height: calc(100% - 2px);
        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
    .multiselect__tag {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1px 1px 1px 0;
        border-radius: 12px;
        padding: 0 4px 0 8px;
        height: 24px;
        font-size: 12px;
        color: @bg-color;
        background-color: @bg-color-middle;
        span {
            overflow: hidden;
            max-width: 100%;
        }

        span:nth-child(2) {
            margin-left: 4px;
        }

        @media(max-height: 900px) {
          margin: 1px 1px 1px 0;
        }

        @media(max-height: 720px) {
          margin: 1px 1px 1px 0;
        }
    }
    .multiselect__placeholder {
        margin-bottom: 0;
        color: @text-color-light;
    }
    .multiselect__content-wrapper {
        width: fit-content;
        margin-top: -2px;
        border-radius: 3px;
        border: 2px solid @text-color-lighter;
        background-color: #ffffff;
        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
    .multiselect__option {
        margin: 1px 1px 1px 0;
        padding: 8px 16px;
        min-height: 0;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: @text-color;
        &.multiselect__option--highlight {
            color: @text-color;
            background-color: @bg-color-light;
        }
    }
    .multiselect__option--selected {
        font-weight: 400;
        color: @text-color;
        background-color: @bg-color-light;
    }
    .multiselect__option--selected.multiselect__option--highlight {
        color: @text-color;
        background-color: @bg-color-light;
    }
    .multiselect.sm {
        padding-top: 2px;
        min-height: 24px;
        height: 24px;
        width: 60px;

        .multiselect__select {
            padding: 0;
            width: 16px;
            height: 24px;
            &::before {
                border-color: @text-color-light transparent transparent;
                color: @text-color-light;
            }
        }
        .multiselect__tags {
            border: none;
            border-radius: 0;
            min-height: 24px;
            height: 24px;
            margin: 1px 1px 1px 0;
            padding: 0 24px 0 8px;
        }
        .multiselect__single {
            vertical-align: middle;
            margin-bottom: 0;
            padding-left: 0;
            min-height: 0;
            font-size: 14px;
            line-height: 1.2;
        }
        .multiselect__content-wrapper {
            border-radius: @br;
            border: 1px solid @text-color-lighter;
        }
        .multiselect__option {
            padding: 8px;
            font-size: 14px;
        }
    }
</style>
